import React, {useEffect, useState} from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  ComposedChart,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import { Select, MenuItem, FormControl, InputLabel, Grid, Paper } from "@mui/material";
import styled from '@emotion/styled'
import SpinnerPage from "../../components/SpinnerPage";
import { APP_TYPE, APP_TYPES } from "../../constants/app";
import {useSelector} from "react-redux";
import { getMonthlyAnalysis, listAnalysesInMonth } from '../../apis/DashboardApi';
import { listMessagesByMonth2 } from "../../apis/MessageApi";
import { utils as xlsxUtils, writeFile } from 'xlsx';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const startMonth = 10;
const startYear = 2023;
const monthList = [];
for (let year = startYear; year <= currentYear; year++) {
  const start = (year === startYear) ? startMonth : 1;
  const end = (year === currentYear) ? currentMonth : 12;
  for (let month = start; month <= end; month++) {
    monthList.push({
      label: `${year}年${String(month).padStart(2, '0')}月`,
      value: `${year}-${String(month).padStart(2, '0')}`
    });
  }
}

const months = monthList.reverse();


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const TotalWrapper = styled(Paper)`
  padding: 20px;
`

const DashboardPage = () => {
  const [selectedMonth, setSelectedMonth] = useState(`${currentYear}-${String(currentMonth).padStart(2, '0')}`);
  const [conversationData, setConversationData] = useState(null);
  const [hourlyData, setHourlyData] = useState([]);
  const [chatData, setChatData] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const { userInfo } = useSelector((state) => state.users)
  const [monthlyUserCnt, setMonthlyUserCnt] = useState(0);
  const [dayAnalyses, setDayAnalyses] = useState([]);
  console.log(dayAnalyses)
  const governmentId = userInfo?.governmentId;
  const key = APP_TYPE===APP_TYPES.OUTSIDE_ADMIN && "external" ||
    APP_TYPE===APP_TYPES.INSIDE_ADMIN && "internal" ||
    "all";

  const handleMonthChange = async (event) => {
    setSelectedMonth(event.target.value);
  };

  const fetchAnalysisData = async (monthYear, governmentId) => {
    try {
      setIsLoading(true);
      setConversationData(null);
      setChatData(null);
      const res = await listAnalysesInMonth(governmentId, monthYear);
      if (res.success) {
        setDayAnalyses(res.data);      }
    } catch (error) {
      console.error('Error fetching analysis data', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMonthlyUserCnt = async (selectedMonth, governmentId) => {
    try {
      setIsLoading(true);
      setConversationData(null);
      setChatData(null);
      const res = await getMonthlyAnalysis(governmentId, selectedMonth);
      if (res.data) {
        const data = res.data;
        if (data && APP_TYPE != APP_TYPES.ADMIN) {

          setMonthlyUserCnt(data[`${key}UserCount`]);
          if (data[`${key}ConversationCount`] > 0) {
            setConversationData([
              {name: '良い', value: data[`${key}GoodEvalCount`] ?? 0},
              {name: '悪い', value: data[`${key}BadEvalCount`] ?? 0},
              {
                name: '評価なし',
                value: data[`${key}ConversationCount`]  - (data[`${key}GoodEvalCount`] + data[`${key}BadEvalCount`])
              }
            ])
          }

          if ( data[`${key}LikeCount`] > 0 || data[`${key}DislikeCount`] > 0) {
            setChatData([{name: '良い', value: data[`${key}LikeCount`] ?? 0},
              {name: '悪い', value: data[`${key}DislikeCount`] ?? 0}
            ])
          }
        }
        else {
          setMonthlyUserCnt(data?.externalUserCount + data?.internalUserCount);
          if (data?.internalConversationCount + data?.externalConversationCount > 0) {
            setConversationData([
              {name: '良い', value: data?.externalGoodEvalCount+data?.internalGoodEvalCount ?? 0},
              {name: '悪い', value: data?.externalBadEvalCount+data?.internalBadEvalCount ?? 0},
              {
                name: '評価なし',
                value: (data?.internalConversationCount + data?.externalConversationCount)  - (data?.intenalGoodEvalCount+data?.extenalGoodEvalCount+data?.intenalBadEvalCount+data?.extenalBadEvalCount),
              }
            ])
          }

          if ( data?.externalLikeCount+data?.internalLikeCount > 0 || data?.externalDislikeCount+data?.internalDislikeCount > 0) {
            setChatData([{name: '良い', value: data?.externalLikeCount+data?.internalLikeCount ?? 0},
              {name: '悪い', value: data?.externalDislikeCount+data?.internalDislikeCount ?? 0}
            ])
          }
        }
      }
      else {
        setMonthlyUserCnt(0);
      }
    }
    catch (e) { /* empty */ }
  }

  const DAYS_IN_MONTH = {
    "01": 31,
    "02": 28,
    "03": 31,
    "04": 30,
    "05": 31,
    "06": 30,
    "07": 31,
    "08": 31,
    "09": 30,
    10: 31,
    11: 30,
    12: 31,
  };

  const isLeapYear = (year) => {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const getDaysInMonth = (yearMonth) => {
    const [year, month] = yearMonth.split("-");
    // 2月かつうるう年の場合は29日を返す
    if (month === "02" && isLeapYear(parseInt(year))) {
      return 29;
    }
    return DAYS_IN_MONTH[month];
  };


  const formatHourlyData = async (monthYear, governmentId) => {
    try {
      setIsLoading(true);
      setConversationData(null);
      setChatData(null);
      const res = await listMessagesByMonth2(governmentId, monthYear);
      if (res.success) {
        const daysInMonth = getDaysInMonth(monthYear);
        const hourlyStats = Array.from({ length: daysInMonth }, (_, day) => ({
          day: day + 1,
          ...Array.from({ length: 24 }, (_, hour) => ({
            [`hour${hour}`]: 0,
          })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        }));

        res.data.forEach((message) => {
          const utcDate = new Date(message.createdAt);
          const jstDate = new Date(utcDate.getTime() + 9 * 60 * 60 * 1000);
          const day = jstDate.getUTCDate();
          const hour = jstDate.getUTCHours();

          if (hourlyStats[day - 1]) {
            hourlyStats[day - 1][`hour${hour}`]++;
          }
        });

        setHourlyData(hourlyStats);
      }
    } catch (error) {
      console.error("Error fetching analysis data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMaxValue = (data) => {
    return Math.max(...data.flatMap((row) => Array.from({ length: 24 }, (_, hour) => row[`hour${hour}`])));
  };

  const getOrderedHours = () => {
    const hours = [];
    for (let i = 6; i < 24; i++) hours.push(i);
    for (let i = 0; i < 6; i++) hours.push(i);
    return hours;
  };

  const getHourTotal = (hour, data) => {
    return data.reduce((sum, row) => sum + row[`hour${hour}`], 0);
  };

  const getDayTotal = (data, dayIndex) => {
    return getOrderedHours().reduce((sum, hour) => sum + data[dayIndex][`hour${hour}`], 0);
  };

  const handleExcelDownload = () => {
    const excelData = [
      ['時間/日付', ...hourlyData.map((_, i) => `${i + 1}日`), '合計'],
      ...getOrderedHours().map(hour => [
        `${hour}時`,
        ...hourlyData.map(row => row[`hour${hour}`]),
        getHourTotal(hour, hourlyData)
      ]),
      [
        '合計',
        ...hourlyData.map((_, index) => getDayTotal(hourlyData, index)),
        hourlyData.reduce((sum, _, index) => sum + getDayTotal(hourlyData, index), 0)
      ]
    ];

    const ws = xlsxUtils.aoa_to_sheet(excelData);
    const wb = xlsxUtils.book_new();

    ws['!cols'] = [{ wch: 10 }];
    wb.Workbook = {
      Views: [
        {
          RTL: false
        }
      ]
    };

    xlsxUtils.book_append_sheet(wb, ws, '時間帯別質問数');
    const fileName = `時間帯別質問数_${selectedMonth}.xlsx`;

    writeFile(wb, fileName, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'file',
      compression: true,
      codepage: 65001
    });
  };

  useEffect(() => {
    fetchAnalysisData(selectedMonth, governmentId);
    fetchMonthlyUserCnt(selectedMonth, governmentId);
    formatHourlyData(selectedMonth, governmentId);
  }, [selectedMonth, governmentId]);
  return (
    <>
      {isLoading ? (
        <SpinnerPage />
      ) : (
        <>
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              padding: "10px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControl
              variant="outlined"
              style={{
                width: "200px",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <InputLabel id="month-select-label">月を選択</InputLabel>
              <Select labelId="month-select-label" value={selectedMonth} onChange={handleMonthChange} label="月を選択">
                {months.map((month, index) => (
                  <MenuItem key={index} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ padding: "20px" }}>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={4}>
                <TotalWrapper>
                  <h3>使用人数</h3>
                  <h3>{monthlyUserCnt}</h3>
                </TotalWrapper>
              </Grid>
              <Grid item xs={4}>
                <TotalWrapper>
                  <h3>使用回数</h3>
                  <h3>
                    {dayAnalyses.reduce((s, x) => {
                      if (APP_TYPE === APP_TYPES.OUTSIDE_ADMIN) {
                        return s + (x.externalMessage || 0);
                      }
                      if (APP_TYPE === APP_TYPES.INSIDE_ADMIN) {
                        return s + (x.internalMessage || 0);
                      }
                      return s + (x.externalMessage || 0) + (x.internalMessage || 0);
                    }, 0)}
                  </h3>
                </TotalWrapper>
              </Grid>
            </Grid>
            <Grid container marginBottom={2}>
              <Grid item xs={12}>
                <Paper style={{ padding: 10 }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }} className="mb-4">
                    <h3>日別のユーザー数/質問回数</h3>
                  </div>
                  {dayAnalyses.length ? (
                    <ResponsiveContainer width="100%" height={400}>
                      <ComposedChart
                        data={dayAnalyses}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 20,
                          left: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="japanDay" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey={`${key}UserCount`} name="ユーザー数" barSize={20} fill="#8884d8" />
                        <Line type="monotone" dataKey={`${key}Message`} name="質問回数" stroke="#ff7300" />
                      </ComposedChart>
                    </ResponsiveContainer>
                  ) : (
                    "データがありません"
                  )}
                </Paper>
              </Grid>
            </Grid>
            <Grid container marginBottom={2}>
              <Grid item xs={12}>
                <Paper style={{ padding: 20 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                    <h3>時間帯別の質問数</h3>
                    {hourlyData && hourlyData.length > 0 && (
                      <button
                        onClick={handleExcelDownload}
                        style={{
                          padding: '8px 16px',
                          backgroundColor: '#2B6CF5',
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px'
                        }}
                      >
                        Excelダウンロード
                      </button>
                    )}
                  </div>
                  {hourlyData && hourlyData.length > 0 ? (
                    <div style={{ overflowX: "auto" }}>
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: 10,
                                border: "1px solid #ddd",
                                whiteSpace: "nowrap",
                                backgroundColor: "white",
                                position: "sticky",
                                left: 0,
                                zIndex: 2,
                              }}
                            >
                              時間/日付
                            </th>
                            {hourlyData.map((_, i) => (
                              <th key={i} style={{ padding: 10, border: "1px solid #ddd", textAlign: "center" }}>
                                {`${i + 1}日`}
                              </th>
                            ))}
                            <th style={{ padding: 10, border: "1px solid #ddd", textAlign: "center", backgroundColor: "#f0f0f0" }}>
                              合計
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {getOrderedHours().map((hour) => (
                            <tr key={hour}>
                              <td
                                style={{
                                  padding: 10,
                                  border: "1px solid #ddd",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "white",
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 1,
                                }}
                              >{`${hour}時`}</td>
                              {hourlyData.map((row) => (
                                <td
                                  key={row.day}
                                  style={{
                                    padding: 10,
                                    border: "1px solid #ddd",
                                    textAlign: "center",
                                    backgroundColor: `rgba(136, 132, 216, ${
                                      row[`hour${hour}`] / (getMaxValue(hourlyData) || 1)
                                    })`,
                                    color: row[`hour${hour}`] > 75 ? "white" : "black",
                                  }}
                                >
                                  {row[`hour${hour}`]}
                                </td>
                              ))}
                              <td
                                style={{
                                  padding: 10,
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  fontWeight: "bold",
                                }}
                              >
                                {getHourTotal(hour, hourlyData)}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              style={{
                                padding: 10,
                                border: "1px solid #ddd",
                                fontWeight: "bold",
                                backgroundColor: "#f0f0f0",
                                position: "sticky",
                                left: 0,
                                zIndex: 1,
                              }}
                            >
                              合計
                            </td>
                            {hourlyData.map((_, index) => (
                              <td
                                key={index}
                                style={{
                                  padding: 10,
                                  border: "1px solid #ddd",
                                  textAlign: "center",
                                  backgroundColor: "#f0f0f0",
                                  fontWeight: "bold",
                                }}
                              >
                                {getDayTotal(hourlyData, index)}
                              </td>
                            ))}
                            <td
                              style={{
                                padding: 10,
                                border: "1px solid #ddd",
                                textAlign: "center",
                                backgroundColor: "#f0f0f0",
                                fontWeight: "bold",
                              }}
                            >
                              {hourlyData.reduce((sum, _, index) => sum + getDayTotal(hourlyData, index), 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>データがありません</div>
                  )}
                </Paper>
              </Grid>
            </Grid>
            <Grid container mt={2} spacing={2}>
              <Grid item xs={6}>
                <Paper style={{ padding: 10 }}>
                  <h3>回答への評価</h3>
                  {chatData ? (
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie dataKey="value" data={chatData} cx="50%" cy="50%" fill="#82ca9d">
                          {chatData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    "データがありません"
                  )}
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{ padding: 10 }}>
                  <h3>Chatの評価</h3>
                  {conversationData ? (
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie dataKey="value" data={conversationData} cx="50%" cy="50%" fill="#82ca9d">
                          {conversationData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    "データがありません"
                  )}
                </Paper>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardPage;
